"use client"

import { Fragment } from "react"
import { queueRemoveItemFromCart } from "src/components/(checkout)/cart/api"
import { useCart } from "src/components/(checkout)/cart/state"
import { formatTotals } from "src/components/(checkout)/cart/totals"
import { DeleteButton } from "src/components/common/DeleteButton"
import { Divider } from "src/components/common/Divider"
import { LineItemUnitPrice } from "src/components/common/LineItemUnitPrice"
import { LineItemVariant } from "src/components/common/LineItemVariant"
import { PrimaryButton } from "src/components/common/PrimaryButton"
import { totalsText } from "src/components/common/totalsText"
import { Thumbnail } from "src/components/product/components/Thumbnail"
import { SectionLoadSpinner } from "src/components/skeletons/components/SectionLoadSpinner"
import { A } from "src/components/unstyled/A"
import { routes } from "src/config/routes"
import { getCountryCodeOrThrow } from "src/localization/state"
import { localizeText } from "src/localization/utils"

export const CartDropdownItems = () => {
  const cart = useCart()

  if (!cart) {
    return <SectionLoadSpinner />
  }

  const T = localizeText(text)
  const countryCode = getCountryCodeOrThrow()
  const { taxTotal, total } = formatTotals(cart)

  return (
    <>
      <div className="max-h-[386px] p-4 flex flex-col gap-4 overflow-y-auto overflow-x-hidden border-b">
        {cart.items.map((item, index) => {
          const href = routes.format_product(item.product.handle)
          const currencyCode = cart.region.currency_code

          return (
            <Fragment key={item.id}>
              {index > 0 && <Divider />}

              <div className="grid grid-cols-[auto,1fr,auto] items-center gap-x-4 gap-y-2">
                <A href={href} className="w-24 row-span-2">
                  <Thumbnail src={item.thumbnail} square />
                </A>

                <div>
                  <A href={href}>
                    <div className="font-medium truncate">{item.title}</div>
                  </A>

                  <LineItemVariant item={item} />
                </div>

                <div className="gap-x-1 flex items-center col-start-3 row-start-1 text-sm font-normal">
                  {item.quantity > 1 && <p>{item.quantity}x </p>}
                  <LineItemUnitPrice item={item} currencyCode={currencyCode} countryCode={countryCode} />
                </div>

                <div className="col-span-2 col-start-2 row-start-2 mr-auto">
                  <DeleteButton
                    onClick={() =>
                      queueRemoveItemFromCart({
                        oldCart: cart,
                        lineId: item.id,
                      })
                    }
                    showText
                  />
                </div>
              </div>
            </Fragment>
          )
        })}
      </div>

      <div className="flex flex-col gap-5 p-4 text-sm font-normal">
        <div className="flex items-center justify-between">
          <div>
            <div className="font-semibold">{T.total}</div>
            <div className="text-subtle">{T.format_taxLine(taxTotal)}</div>
          </div>

          <div className="text-lg font-semibold">{total}</div>
        </div>

        <A href={routes.cart}>
          <PrimaryButton as="div" color="primary" size="small">
            {T.goToCart}
          </PrimaryButton>
        </A>
      </div>
    </>
  )
}

const de = {
  ...totalsText.de,
  goToCart: "Zum Warenkorb",
}

const en: typeof de = {
  ...totalsText.en,
  goToCart: "Go to cart",
}

const text = { de, en }
