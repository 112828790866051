const de = {
  summary: "Zusammenfassung",
  subtotal: "Zwischensumme",
  discount: "Rabatt",
  giftCard: "Geschenkkarte",
  shipping: "Versand",
  total: "Gesamtsumme",
  freeShipping: "kostenloser Versand",
  noShipping: "entfällt",
  willBeCalculatedAtCheckout: "wird an der Kasse berechnet",
  format_taxLine: (taxTotal: string) => `inkl. MwSt. (${taxTotal})`,
}

const en: typeof de = {
  summary: "Summary",
  subtotal: "Subtotal",
  discount: "Discount",
  giftCard: "Gift card",
  shipping: "Shipping",
  total: "Total",
  freeShipping: "free shipping",
  noShipping: "None",
  willBeCalculatedAtCheckout: "will be calculated at checkout",
  format_taxLine: (taxTotal) => `incl. VAT (${taxTotal})`,
}

export const totalsText = { de, en }
