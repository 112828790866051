import { useCallback, useEffect, useRef } from "react"

/** https://github.com/facebook/react/issues/14099 */
// biome-ignore lint/complexity/noBannedTypes: 'Function' is what we want here.
export function useEventCallback<F extends Function>(f: F) {
  const ref = useRef<F>(f)

  useEffect(() => {
    ref.current = f
  }, [f])

  // @ts-expect-error 'F' could be instantiated with a different subtype of constraint 'Function'.
  // biome-ignore lint/style/noCommaOperator: I think biome is confused here.
  return useCallback<F>((...args: any[]) => (0, ref.current)(...args), [])
}
