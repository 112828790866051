import { XIcon } from "src/components/icons"
import { localizeText } from "src/localization/utils"

export type CloseButtonProps = {
  onClick: () => void
}

export const CloseButton = ({ onClick }: CloseButtonProps) => {
  const T = localizeText(text)

  return (
    <button
      type="button"
      onClick={onClick}
      title={T.close}
      className="text-muted hover:bg-muted/50 hover:text-default right-4 top-4 absolute p-2 rounded-full"
    >
      <XIcon size={24} />
    </button>
  )
}

const de = {
  close: "Schließen",
}

const en: typeof de = {
  close: "Close",
}

const text = { de, en }
