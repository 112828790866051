import clsx from "clsx"
import { StorefrontLineItem } from "src/types/storefront"
import { formatAmount } from "src/utilities/price"

export type LineItemUnitPriceProps = {
  item: StorefrontLineItem
  currencyCode: string
  countryCode: string
}

export const LineItemUnitPrice = ({ item, currencyCode, countryCode }: LineItemUnitPriceProps) => {
  const hasReducedPrice = item.total !== item.original_total

  return (
    <div className="flex flex-col items-center justify-center h-full">
      {hasReducedPrice && (
        <div className="text-muted line-through">
          {formatAmount({
            amount: (item.original_total || 0) / item.quantity,
            currencyCode,
            countryCode,
          })}
        </div>
      )}

      <div
        className={clsx({
          "text-discount": hasReducedPrice,
        })}
      >
        {formatAmount({
          amount: (item.total || 0) / item.quantity,
          currencyCode,
          countryCode,
        })}
      </div>
    </div>
  )
}
