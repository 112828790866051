export type ErrorMessageProps = {
  message: string | null | undefined
}

export const ErrorMessage = ({ message }: ErrorMessageProps) => {
  if (!message) {
    return null
  }

  const cleanedMessage = message.replace(/^Error: /, "")

  return <div className="text-error text-sm font-medium">{cleanedMessage}</div>
}
