"use client"

import { useEffect } from "react"
import { useEventCallback } from "src/hooks/useEventCallback"
import { useIsClient } from "src/hooks/useIsClient"
import { hash } from "src/utilities/hash"
import { useCcm19EmbeddingAccepted } from "../ccm19/useConsented"
import { integrationDebug } from "./api"
import { integrationConsentId } from "./config"
import { setIntegrationLoadState } from "./loaded"
import { useIntegrationNeeded } from "./needed"
import { IntegrationId } from "./types"

export type IntegrationScriptProps = {
  id: IntegrationId
  src: string
  onlyWhenNeeded?: boolean
  autoConsent?: boolean
  onLoad?: () => void
  [key: string]: any
}

export const IntegrationScript = ({
  id: integrationId,
  src,
  onlyWhenNeeded,
  autoConsent,
  onLoad,
  ...attributes
}: IntegrationScriptProps) => {
  const consentName = integrationConsentId[integrationId]
  const consented = useCcm19EmbeddingAccepted(consentName)
  const needed = useIntegrationNeeded(integrationId)
  const isClient = useIsClient()
  const srcHash = hash(src)
  const scriptId = integrationId + "_" + srcHash

  const getElement = () => {
    if (isClient) {
      return document.getElementById(scriptId)
    }
  }

  const unmount = useEventCallback(() => {
    const script = getElement()

    if (script) {
      script.remove()
      integrationDebug(integrationId, "script removed", srcHash)
    }
  })

  // Unmount script on component unmount.
  useEffect(() => {
    return unmount
  }, [unmount])

  // Reset inserted state when src changes.
  // biome-ignore lint/correctness/useExhaustiveDependencies:
  useEffect(() => {
    unmount()
  }, [src])

  if (!isClient) {
    return null
  }

  if (getElement()) {
    return null
  }

  if (!consented && !autoConsent) {
    return null
  }

  if (onlyWhenNeeded && !needed) {
    return null
  }

  const script = document.createElement("script")

  script.id = scriptId
  script.src = src
  script.async = true
  script.referrerPolicy = "origin"

  for (const [key, value] of Object.entries(attributes)) {
    script.setAttribute(key, String(value))
  }

  script.onload = (event) => {
    integrationDebug(integrationId, "loaded", event)
    setIntegrationLoadState(integrationId, "loaded")
    onLoad?.()
  }

  script.onerror = (event) => {
    integrationDebug(integrationId, "errored", event)
    setIntegrationLoadState(integrationId, "errored")
  }

  document.body.appendChild(script)
  integrationDebug(integrationId, "script inserted", srcHash)

  return null
}
