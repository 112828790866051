import Link, { LinkProps } from "next/link"
import { ComponentProps } from "react"
import { DEFAULT_COUNTRY_CODE } from "src/localization/localization"
import { getCountryCodeOrThrow } from "src/localization/state"

export type BaseComponentProps = LinkProps & ComponentProps<"a">

export type AProps = Omit<BaseComponentProps, "href" | "onClick"> & {
  href?: string
  onClick?: () => void
}

/**
 * Use this component to create a Next.js `<Link />` that persists the current
 * country code in the url, without having to explicitly pass it as a prop.
 */
export const A = ({ children, href, ...props }: AProps) => {
  const countryCode = getCountryCodeOrThrow()

  if (!href) {
    return <div className={props.className}>{children}</div>
  }

  const localizedHref = countryCode === DEFAULT_COUNTRY_CODE ? href : `/${countryCode}${href}`

  return (
    <Link {...props} href={localizedHref}>
      {children}
    </Link>
  )
}
