import clsx from "clsx"
import { ComponentProps } from "react"
import { Spinner } from "src/components/icons"
import { A, AProps } from "src/components/unstyled/A"

export type BaseComponentProps = ComponentProps<"button"> & AProps & ComponentProps<"div">

export type PrimaryButtonProps = BaseComponentProps & {
  loading?: boolean
  as?: "button" | "a" | "div"
  size: "small" | "large"
  color: "primary" | "secondary" | "tertiary" | "error"
  reducedPadding?: boolean
}

export const PrimaryButton = ({
  disabled,
  loading,
  as,
  size,
  color,
  reducedPadding,
  children,
  ...props
}: PrimaryButtonProps) => {
  const Element = as === "a" || props.href ? A : as || "button"
  const inert = disabled || loading

  return (
    <Element
      {...props}
      disabled={inert}
      className={clsx(
        props.className,
        "hover:transition-none sm:w-auto flex items-center gap-2 justify-center w-full font-semibold uppercase transition-[color,background-color,transform] rounded-full whitespace-nowrap",
        {
          "shadow active:scale-95 active:shadow-none": !inert,
          "cursor-not-allowed pointer-events-auto": inert,
          "bg-gray-400 text-white": loading,
          "bg-gray-200 opacity-50": disabled,

          "py-1 px-2": reducedPadding && size === "small",
          "py-2 px-4": reducedPadding && size === "large",
          "py-3 px-6": !reducedPadding && size === "small",
          "py-3 px-12": !reducedPadding && size === "large",

          "text-sm min-h-11": size === "small",
          "text-lg min-h-14": size === "large",

          "bg-brand-primary text-white hover:bg-brand-primary/90 active:bg-brand-primary/80":
            !inert && color === "primary",
          "bg-brand-secondary-light text-brand-primary font-black hover:bg-brand-secondary-light/90 active:bg-brand-secondary-light/80":
            !inert && color === "secondary",
          "bg-gray-50 text-gray-800 hover:bg-gray-100 active:bg-gray-200": !inert && color === "tertiary",
          "bg-error text-white hover:bg-error/90 active:bg-error/80": !inert && color === "error",
        },
      )}
    >
      {loading ? <Spinner className="w-6 h-6 mx-4 -my-3" /> : children}
    </Element>
  )
}
