/** https://docs.ccm19.com/api/javascript-apis/#javascript-events */
export enum Ccm19Event {
  /** Ein Embedding wurde durch den Nutzer akzeptiert. Diese Events erfolgen
   * beim Erteilen des Consents durch den Besucher und werden für jeden
   * Seitenaufruf wiederholt. */
  EmbeddingAccepted = "ccm19EmbeddingAccepted",

  /** Wird ausgelöst, sobald das letzte sichtbare CCM19-Widget-Element
   * geschlossen wird. Dabei kann es sich um ein Resultat eines Klicks auf einen
   * Speichern-Button handeln, der zum Schließen des Widgets führt. */
  WidgetClosed = "ccm19WidgetClosed",
}
