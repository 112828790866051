import { getCountryCodeOrThrow } from "src/localization/state"
import { StorefrontCart, StorefrontOrder } from "src/types/storefront"
import { formatAmount, roundPrice } from "src/utilities/price"

export function formatShippingTotal(data: StorefrontCart | StorefrontOrder) {
  const countryCode = getCountryCodeOrThrow()
  const currencyCode = data.region?.currency_code

  if (!currencyCode) {
    throw new Error(`The ${data.object} is missing currencyCode`)
  }

  const shippingAmount = data.shipping_methods?.reduce((sum, method) => sum + (method.total || 0), 0) || 0
  const shippingIsFree = !shippingAmount
  const shippingTotal = formatAmount({
    amount: shippingAmount,
    currencyCode,
    countryCode,
  })

  return {
    shippingTotal,
    shippingIsFree,
  }
}

export function formatTotals(data: StorefrontCart | StorefrontOrder) {
  const countryCode = getCountryCodeOrThrow()
  const currencyCode = data.region?.currency_code

  if (!currencyCode) {
    throw new Error(`The ${data.object} is missing currencyCode`)
  }

  const subTotal = formatAmount({
    amount: data.items?.reduce((sum, item) => sum + (item.original_total || 0), 0) || 0,
    currencyCode,
    countryCode,
  })
  const discountTotal = formatAmount({
    amount: data.items?.reduce((sum, item) => sum + roundPrice((item.original_total || 0) - (item.total || 0)), 0) || 0,
    currencyCode,
    countryCode,
  })
  const giftCardTotal = formatAmount({
    amount: (data.gift_card_total || 0) + (data.gift_card_tax_total || 0),
    currencyCode,
    countryCode,
  })
  const taxTotal = formatAmount({
    amount: data.tax_total || 0,
    currencyCode,
    countryCode,
  })
  const total = formatAmount({
    amount: data.total || 0,
    currencyCode,
    countryCode,
  })

  return {
    subTotal,
    discountTotal,
    giftCardTotal,
    taxTotal,
    total,
  }
}
