import clsx from "clsx"
import { ReactNode } from "react"
import { A } from "src/components/unstyled/A"

export type HeaderLinkProps = {
  href: string
  label: string
  children: ReactNode
  className?: string
}

export const HeaderLink = ({ href, label, children, className }: HeaderLinkProps) => {
  return (
    <A
      className={clsx(
        "w-12 h-12 text-subtle hover:text-black border-2 border-transparent hover:border-brand-secondary-light transition-colors hover:transition-none ease-out rounded-md flex items-center justify-center",
        className,
      )}
      href={href}
      aria-label={label}
      title={label}
    >
      {children}
    </A>
  )
}
