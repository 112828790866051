"use client"

import clsx from "clsx"
import { skeleton } from "src/components/skeletons/skeletonStyles"
import { StorefrontLineItem } from "src/types/storefront"
import { isDummyVariant } from "src/utilities/product"

export type LineItemVariantProps = {
  item: StorefrontLineItem
  initializing?: boolean
}

export const LineItemVariant = ({ item, initializing }: LineItemVariantProps) => {
  if (isDummyVariant(item.variant)) {
    return null
  }

  const title = item.variant?.name

  return <span className={clsx("text-sm", initializing && skeleton.text)}>{title}</span>
}
