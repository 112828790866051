import { create as createStore } from "zustand"
import { IntegrationId } from "./types"

const defaultState: { [key in IntegrationId]?: boolean } = {}

const store = createStore(() => defaultState)

export function useIntegrationNeeded(id: IntegrationId) {
  return store((state) => state[id])
}

export function setIntegrationNeeded(id: IntegrationId, needed: boolean) {
  if (store.getState()[id] !== needed) {
    store.setState({ [id]: needed })
  }
}
