import { create as createStore } from "zustand"
import { IntegrationId } from "./types"

export type IntegrationLoadState = "loaded" | "errored"

const defaultState: { [key in IntegrationId]?: IntegrationLoadState } = {}

const store = createStore(() => defaultState)

export function useIntegrationLoaded(id: IntegrationId) {
  return store((state) => state[id])
}

export function setIntegrationLoadState(id: IntegrationId, status: IntegrationLoadState) {
  if (store.getState()[id] !== status) {
    store.setState({ [id]: status })
  }
}
