import { ComponentProps } from "react"
import { preload } from "react-dom"
import { SetRequired } from "type-fest"

export type ImgProps = SetRequired<ComponentProps<"img">, "src" | "alt" | "loading">

export const Img = (props: ImgProps) => {
  if (props.loading === "eager") {
    preload(props.src, {
      as: "image",
      imageSrcSet: props.srcSet,
      imageSizes: props.sizes,
    })
  }

  // biome-ignore lint/a11y/useAltText:
  return <img {...props} referrerPolicy="strict-origin-when-cross-origin" />
}
