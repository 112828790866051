import clsx from "clsx"
import { FC, ReactNode } from "react"
import { PlaceholderImage } from "src/components/icons/PlaceholderImage"
import { Img } from "src/components/unstyled/Img"
import {
  toHorizontalProductImageCdnUrl,
  toSquareProductImageCdnUrl,
  toVerticalProductImageCdnUrl,
} from "src/utilities/images"

type ImageOrPlaceholderProps = {
  src: string | null | undefined
}

const ImageOrPlaceholder = ({ src }: ImageOrPlaceholderProps) => {
  return src ? (
    <Img
      src={src}
      alt={src.split("/").pop()!}
      loading="lazy"
      className="rounded-lg absolute inset-0 object-cover w-full h-full"
    />
  ) : (
    <div className="absolute inset-0 flex items-center justify-center w-full h-full">
      <PlaceholderImage />
    </div>
  )
}

export type ThumbnailProps = {
  src: string | null | undefined
  square?: boolean
  panorama?: boolean
  className?: string
  children?: ReactNode
}

export const Thumbnail: FC<ThumbnailProps> = ({ src, square, panorama, className, children }) => {
  if (square && panorama) {
    throw new Error("Thumbnail cannot be both square and panorama")
  }

  const transformedSrc = square
    ? toSquareProductImageCdnUrl(src)
    : panorama
      ? toHorizontalProductImageCdnUrl(src)
      : toVerticalProductImageCdnUrl(src)

  return (
    <div
      className={clsx("relative overflow-hidden p-4 bg-gray-200 rounded-xl shadow", className, {
        "aspect-[2/3]": !square && !panorama,
        "aspect-[1/1]": square,
        "aspect-[3/2]": panorama,
      })}
    >
      <ImageOrPlaceholder src={transformedSrc} />
      {children}
    </div>
  )
}
