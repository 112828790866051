import { Ccm19EmbeddingName } from "./embeddings"

export const consentNames = Object.values(Ccm19EmbeddingName)

export type CcmAcceptedEmbedding = {
  name: Ccm19EmbeddingName
  [key: string]: unknown
}

export type Ccm19EmbeddingAcceptedEvent = {
  name: Ccm19EmbeddingName
  [key: string]: unknown
}
